import React from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Box,
  AppBar,
  Typography,
  Grid,
} from "@mui/material";

const appBar = css`
  min-height: 8vh;
  color: black;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const appBarElement = css`
  min-height: 8vh;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const appBarElement2 = css`
  min-height: 8vh;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const headerText = css`
  color: black;
  font-size: 20px;
  font-weight: normal;
`;

const Header: React.FC<{
  windowName: string;
  showOpview?: boolean;
}> = ({ windowName }) => {
  return (
    <Box>
      <AppBar position="static" css={appBar}>
        <Grid container>
          <Grid xs={1} css={appBarElement}>
            <img
              src={`${window.location.origin}/image/sejlogo.png`}
              alt="SEJアイコン"
              style={{ height: "7vh" }}
            />
          </Grid>

          <Grid item xs={10} css={appBarElement2}>
            <Typography variant="h5" component="p" css={headerText}>
              {windowName}
            </Typography>
            <Typography
              variant="h5"
              component="p"
              css={headerText}
            ></Typography>
          </Grid>
        </Grid>
      </AppBar>
    </Box>
  );
};

export default Header;
