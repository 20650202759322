import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, microsoft365Login } from "../src/firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { CircularProgress } from "@mui/material";
import { INITIAL_PAGE, LOGIN_PAGE, ENV_LOCAL, ENV_DEV } from "./const/constant";



const App: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(false);
  useEffect(() => {
    const unSub = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        // firebase認証済み
        navigate(INITIAL_PAGE);
      } else {
        // firebase認証未
        // (キャッシュ削除状態だと重いので注意)
        if (
          process.env.REACT_APP_MODE === ENV_LOCAL ||
          process.env.REACT_APP_MODE === ENV_DEV
        ) {
          // ローカルまたは開発環境ではログイン画面へ遷移する
          // navigate(LOGIN_PAGE);
        }
        // ローカル開発環境で実施するときはドメイン追加が必要なので注意
        // https://console.firebase.google.com/u/0/project/digital-tools-external-dev/authentication/settings
        microsoft365Login()
      }
    });
    return () => {
      unSub();
    };
  }, []);

  return (
    <>
      {loginLoading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            size={"40vh"}
            style={{
              color: "#ccc",
              top: "30vh",
              position: "absolute",
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
